import auth from "./auth";
import experiments from "./experiments";
import owner from "./owner";
import lead from "./lead";
import property from "./property";
import propertyStatusCategory from "./propertyStatusCategory";
import propertyDocuments from "./propertyDocuments";
import contact from "./contact";

export default {
  auth: jest.fn(() => auth),
  experiments: jest.fn(() => experiments),
  owner: jest.fn(() => owner),
  lead: jest.fn(() => lead),
  property: jest.fn(() => property),
  propertyStatusCategory: jest.fn(() => propertyStatusCategory),
  propertyDocuments: jest.fn(() => propertyDocuments),
  contact: jest.fn(() => contact),
};
